import React from 'react';
import Layout from '../global/Layout';
const Test = () => (
  <Layout
    seo={{
      slug: 'test',
      title: '4Geeks Academy - Test Page',
      description: '4Geeks Academy - Testr Page ',
      image: "",
      keywords: []
    }}
    context={{
      lang: "us"
    }}
  >

  </Layout>
);
export default Test;